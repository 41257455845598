.login-container {
  max-width: 400px;
  margin: 0px auto;
  padding-top: 24vh;
  & > .toggleBtn {
    text-align: center;
    padding: 20px 0px;
    button {
      border: none;
      background: none;
      font-size: 16px;
      color: $dark;
    }
  }
  h2 {
    text-align: center;
    font-size: 20px;
    padding: 15px 0px;
    color: $green;
  }
  .page-logo {
    text-align: center;
    margin-bottom: 30px;
    img {
      max-width: 100%;
    }
  }
  .login-box {
    background: white;
    box-sizing: border-box;
    padding: 15px 20px;
    box-shadow: $boxShadow;
    border-radius: 5px;
    max-width: 95%;
    margin: 0 auto;
    button {
      width: 100%;
      padding: 10px;
      font-size: 18px;
      border: none;
      color: #fff;
      background: $green;
      border-radius: 5px;
      transition: all .2s ease-in-out;
      cursor: pointer;
      &:hover {
        background: $greenHover;
      }
    }
  }
  .error {
    text-align: center;
    font-size: 15px;
    display: block;
    color: $red;
  }
}
.re-captcha {
  margin: 10px 0px 15px;
}