$light: #f5f5f5;
$lightGrey: #ececec;
$borderColor: #c3c3c3;
$green: #019541;
$greenHover: #007734;
$darkGery: #555a02;
$darkGeryHover: #464a01;
$red: #e31e24;
$redHover: #b61d22;
$transparentBg: rgba(253, 253, 253, 0.7);
$submited: #00b700;

$dark: #333333;


$boxShadow: 0px 0px 20px 0px $borderColor;