@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap&subset=latin-ext');

body {
	background: $lightGrey;
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
textarea {
	min-height: 180px;
}
button,
.button {
	width: 100%;
	padding: 10px;
	font-size: 18px;
	border: none;
	color: #fff;
	background: $green;
	border-radius: 5px;
	transition: all .2s ease-in-out;
	cursor: pointer;
	text-decoration: none;
	text-align: center;
	line-height: normal;
	&.more-info-btn {
    display: block;
    width: 100px;
    padding: 5px;
    height: 29px;
    margin-top: 7px;
    font-size: 16px;
	}
	&:hover {
		background: $greenHover;
	}
	&.submited {
		background: $borderColor;
		cursor: default;
		pointer-events: none;
		&.report-button {
			background: $borderColor;
		}
	}
	&.dark {
		background: $darkGery;
		&:hover {
			background: $darkGeryHover;
		}
	}
}
.more-info-btn {
	max-width: 100px;
	box-sizing: border-box;
	margin-right: 5px;
}
.app {
	min-height: 100vh;
}
.actions-column {
	width: 100px;
	max-width: 100px;
}
.input-container {
	display: flex;
	flex-direction: column;
	margin: 10px 0px 20px;
	label {
		display: block;
		font-size: 16px;
		margin-bottom: 5px;
	}
	input {
		outline: none;
	}
	input[type="text"],
	input[type="password"],
	input[type="number"],
	input[type="email"],
	select,
	.date-picker,
	textarea {
    font-size: 16px;
    padding: 0px 5px;
    border: 1px solid $borderColor;
		border-radius: 5px;
		transition: all .2s ease-in-out;
		background: #fff;
		height: 40px;
    width: 100% !important;
    box-sizing: border-box;
		&:focus {
			border: 1px solid black;
		}
		&.linestyle {
			border: none;
			border-bottom: 1px solid $borderColor;
			border-radius: 0px;
		}
	}
	textarea {
		padding: 10px 5px;
	}
	.react-datepicker__input-container {
		width: 100%;
	}
	input[type="submit"],
	button {
    padding: 10px;
    font-size: 18px;
    border: none;
    color: #fff;
    background: $green;
		border-radius: 5px;
		transition: all .2s ease-in-out;
		cursor: pointer;
		&:hover {
			background: $greenHover;
		}
	}
}
.panel {
	min-height: 100vh;
}
.topbar {
	box-shadow: $boxShadow;
	background: #fff;
	padding: 10px 30px 10px 230px;
	display: flex;
	justify-content: space-between;
	height: 54px;
	box-sizing: border-box;
	z-index: 99;
	position: fixed;
	width: 100%;
	top: 0px;
	left: 0px;
	.logo {
		max-width: 173px;
	}
	.toolbar-contant {
		display: flex;
		align-content: center;
	}
}
.panel-content {
	min-height: calc(100vh - 54px);
	display: flex;
	margin-top: 0px;
	padding-top: 54px;
	.sidebar {
    width: 200px;
    min-width: 200px;
    min-height: 100%;
    padding: 54px 0px 0px;
    box-sizing: border-box;
    background: white;
    margin-top: -54px;
    position: fixed;
		z-index: 99;
		&:after {
			content: "";
			position: absolute;
			top: 54px;
			right: 0px;
			height: calc(100% - 54px);
			width: 9px;
			box-shadow: 8px 0px 10px -3px $borderColor;
		}
		h2 {
			font-size: 20px;
			text-transform: uppercase;
			padding: 10px 10px;
			border-top: 1px solid $light;
		}
	}
	.main {
		width: 100%;
		padding: 30px;
		margin-left: 200px;
		box-sizing: border-box;
	}
}
.avatar-container {
	display: flex;
	align-items: center;
	margin-right: 15px;
	.avatar {
		width: 35px;
		height: 35px;
		padding: 4px 1px 0px;
		box-sizing: border-box;
		border: 1px solid $red;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 10px;
		img {
			width: 100%;
		}
	}
	span {
		font-size: 15px;
		text-transform: uppercase;
		white-space: nowrap;
	}
}
.menu {
	.menu-list {
		li a {
			display: block;
			padding: 10px;
			border-top: 1px solid $light;
			color: $dark;
			text-decoration: none;
			transition: all .2s ease-in-out;
			background: #fff;
			&:hover {
				background: $light;
			}
		}
	}
}
.button-logout {
	background: $red;
	border: none;
	color: #fff;
	padding: 5px 15px;
	box-sizing: border-box;
	border-radius: 5px;
	transition: all .2s ease-in-out;
	&:hover {
		background: $redHover;
	}
}
.box {
	box-shadow: $boxShadow;
	background: white;
	box-sizing: border-box;
	border-radius: 5px;
	margin: 10px 0px;
	width: 100%;
	overflow: hidden;
	&.size-1 {
		max-width: 600px;
	}
	&.size-2 {
		max-width: 900px;
	}
	&.size-100 {
		max-width: 100%;
	}
	.box-content {
		padding: 30px 20px;
		.product-img {
			img {
				width: 100%;
				margin-bottom: 10px;
			}
		}
		img {
			min-width: 150px;
		}
	}
	.box-title {
    background: $light;
    padding: 15px 5px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    color: $dark;
	}
}
.list {
	list-style: none;
	.title-li {
		display: flex;
		background: $light;
		span {
			display: block;
			padding: 10px;
			&.flex-1 {
				flex: 1;
			}
			&.flex-2 {
				flex: 2;
			}
			&.flex-3 {
				flex: 3
			}
		}
	}
	.product-item-list {
		.product-item {
			display: flex;
			text-decoration: none;
			color: $dark;
			align-items: center;
			.item-actions {
				display: flex;
				flex: 2;
				button,
				.button {
					margin-right: 5px;
					font-size: 15px;
					padding: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					white-space: pre-line;
				}
			}
			& > * {
				flex: 1;
				padding: 10px;
				color: $dark;
				text-decoration: none;
			}
		}
		&.hide {
			display: none;
		}
	}
	.small-column {
		max-width: 100px;
	}
}
.search {
	margin-bottom: 20px;
	.box-content {
		padding: 5px 20px;
	}
	input {
		text-align: center;
	}
}
.notification {
	position: fixed;
	bottom: 10px;
	right: 10px;
	padding: 15px 40px;
	background: $dark;
	border-radius: 5px;
	font-size: 17px;
	box-shadow: $boxShadow;
	z-index: 999;
	transition: all .2s ease-in-out;
	transform: translateY(70px);
	color: #fff;
	&.show {
		transform: translateY(0px);
	}
	&.success {
		background: $green;
	}
}
.flex-container {
	display: flex;
	&.flex-2 {
		& > div {
			flex: 1;
			width: 50%;
		}
	}
	&.flex-3 {
		& > div {
			flex: 1;
			width: 33.333%;
		}
	}
	&.flex-4 {
		& > div {
			flex: 1;
			width: 25%;
		}
	}
}
.label {
	margin-bottom: 10px;
	display: block;
	max-width: 200px;
	padding: 10px;
	background: $light;
	border-radius: 5px;
	h3 {
		font-size: 15px;
		margin-bottom: 5px;
    color: $red;
	}
	span {
		color: $dark;
		font-size: 18px;
	}
}
.history-item {
	display: flex;
	width: 100%;
	transition: all .2s ease-in-out;
	border-bottom: 1px solid $light;
	span {
		padding: 15px 10px;
		flex: 1;
	}
	&:hover {
		background: $light;
	}

}
.history-labels {
	display: flex;
	width: 100%;
	span {
		padding: 5px 10px;
		flex: 1;
	}
	background: $light;
	padding: 10px 0px;
	border-bottom: 1px solid $dark;
}
.products-list-titles {
	background: $light;
}
.user-link,
.users-list-titles,
.product-link,
.products-list-titles {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	color: $dark;
	text-decoration: none;
	transition: all .2s ease-in-out;
	&:hover {
		background: $light;
	}
	&.hide {
		display: none;
	}
	span {
		flex: 1;
		display: block;
		padding: 0px 10px;
		&.size-1 {
			width: 45px;
			max-width: 45px;
		}
	}
}
.client-record {
	display: flex;
	padding: 5px 0px;
	border-bottom: 1px solid $light;
}
.users-list-titles {
	background: $light;
	padding: 15px 10px;
}
.acc-item {
	display: block;
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: 10px;
	.acc-item-title {
		display: flex;
		padding: 5px;
		align-items: center;
		background: $lightGrey;
		transition: all .2s ease-in-out;
		cursor: pointer;
		&:hover {
			background: $lightGrey;
		}
		& > span {
			flex: 1;
			&:not(.with-events) {
				pointer-events: none;
			}
		}
		.button {
			width: auto;
			padding: 5px 10px;
			font-size: 15px;
			background: $red;
			&:hover {
				background: $redHover;
			}
		}
	}
	.acc-item-content {
		transition: all .2s linear; 
		max-height: 0px;
		overflow: hidden;
		opacity: 0;
		border: 1px solid $lightGrey;
		padding: 0px 10px;
		&.show {
			max-height: 500px;
			padding: 10px;
			opacity: 1;
		}
	}
}
.acc-item-content {
	box-sizing: border-box;
	padding: 10px;
	display: flex;
	.acc-information {
		width: 100%;
	}
	.acc-row {
		display: flex;
		justify-content: space-between;
		width: 100%;
		.label {
			max-width: 260px;
			width: 100%;
			margin: 5px;
			min-width: 150px;
			&.full {
				max-width: 100%;
				box-sizing: border-box;
			}
		}
		&.desc {
			justify-content: flex-start;
			.label {
				max-width: 100%;
				width: auto;
				&:first-of-type {
					margin-right: 10px;
				}
			}
		}
	}
	.acc-item-image {
		max-width: 400px;
		margin-right: 10px;
	}
	.order-btn {
    margin-top: 20px;
    width: auto;
    float: right;
	}
}
.form-buttons-container {
	display: flex;
	justify-content: flex-end;
	button {
		max-width: 150px;
		&.add-prop {
			background: $dark;
			margin-right: 10px;
			max-width: 200px;
		}
	}
}
.addField {
	border: 1px solid $lightGrey;
	box-sizing: border-box;
	padding: 10px;
	margin-bottom: 10px;
	button {
		background: $dark;
	}
}
.register-product {
	display: flex;
	& > div:first-of-type:not(:last-of-type) {
		margin-right: 30px;
	}
	.product-prop {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    background: $light;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
		.input-name {
			font-size: 14px;
			margin-bottom: 5px;
		}
		.input-value {
			font-size: 17px;
		}
	}
}
.product-input-prop {
	position: relative;
	.delete {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 30px;
    background: $red;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
		height: 40px;
		transition: all .2s ease-in-out;
		&:hover {
			background: $redHover;
		}
	}
}
.hamburger {
	display: none;
}
.users-list-titles span:nth-of-type(2),
.user-link span:nth-of-type(2) {
	width: 150px;
	max-width: 180px;
	min-width: 130px;
}
.remove-client-btn {
	max-width: 70px;
	transition: all .2s ease-in-out;
	background: $red;
	&:hover {
		background: $redHover;
	}
}
.back-arrow {
	width: auto;
	color: $dark;
	width: auto;
	color: #fff;
	padding: 5px 10px 5px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	.arrow {
		margin: 9px 15px;
		@include arrowColor(#fff);
	}
}
.copy {
	position: fixed;
	bottom: 0px;
	right: 0px;
	width: 600px;
	max-width: 100%;
	padding: 20px;
	box-sizing: border-box;
	img {
		width: 100%;
	}
}