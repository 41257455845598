/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap&subset=latin-ext");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

.arrow {
  margin: 16px;
  display: inline-block;
  height: 12px;
  position: relative;
  width: 12px; }
  .arrow::after {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-right-style: solid;
    border-right-width: 2px;
    content: '';
    display: inline-block;
    height: 12px;
    left: 0;
    position: absolute;
    top: 0;
    width: 12px; }
  .arrow.is-triangle::after {
    border-style: solid;
    border-width: 0 0 12px 12px;
    height: 0;
    width: 0; }
  .arrow.is-check::after {
    width: 6px; }
  .arrow.arrow-bar::before {
    bottom: 1px;
    content: '';
    height: 20px;
    position: absolute;
    right: 0;
    transform-origin: bottom;
    width: 2px;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .arrow.is-top {
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg); }
  .arrow.is-right {
    -moz-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg); }
  .arrow.is-bottom {
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  .arrow.is-left {
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg); }
  .arrow::after,
  .arrow .arrow::after {
    border-color: red; }
  .arrow.is-triangle::after,
  .arrow .arrow.is-triangle::after {
    border-color: transparent;
    border-bottom-color: red; }
  .arrow::before,
  .arrow .arrow::before {
    background-color: red; }

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #333333;
  animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite; }

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(19px, 0); } }

.loader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: rgba(253, 253, 253, 0.7);
  transition: all .1s ease-in-out; }
  .loader.show {
    visibility: visible;
    z-index: 99999;
    opacity: 1; }
  .loader.hide {
    visibility: hidden;
    z-index: 999;
    opacity: 0; }

body {
  background: #ececec;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

textarea {
  min-height: 180px; }

button,
.button {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  border: none;
  color: #fff;
  background: #019541;
  border-radius: 5px;
  transition: all .2s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: normal; }
  button.more-info-btn,
  .button.more-info-btn {
    display: block;
    width: 100px;
    padding: 5px;
    height: 29px;
    margin-top: 7px;
    font-size: 16px; }
  button:hover,
  .button:hover {
    background: #007734; }
  button.submited,
  .button.submited {
    background: #c3c3c3;
    cursor: default;
    pointer-events: none; }
    button.submited.report-button,
    .button.submited.report-button {
      background: #c3c3c3; }
  button.dark,
  .button.dark {
    background: #555a02; }
    button.dark:hover,
    .button.dark:hover {
      background: #464a01; }

.more-info-btn {
  max-width: 100px;
  box-sizing: border-box;
  margin-right: 5px; }

.app {
  min-height: 100vh; }

.actions-column {
  width: 100px;
  max-width: 100px; }

.input-container {
  display: flex;
  flex-direction: column;
  margin: 10px 0px 20px; }
  .input-container label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px; }
  .input-container input {
    outline: none; }
  .input-container input[type="text"],
  .input-container input[type="password"],
  .input-container input[type="number"],
  .input-container input[type="email"],
  .input-container select,
  .input-container .date-picker,
  .input-container textarea {
    font-size: 16px;
    padding: 0px 5px;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    transition: all .2s ease-in-out;
    background: #fff;
    height: 40px;
    width: 100% !important;
    box-sizing: border-box; }
    .input-container input[type="text"]:focus,
    .input-container input[type="password"]:focus,
    .input-container input[type="number"]:focus,
    .input-container input[type="email"]:focus,
    .input-container select:focus,
    .input-container .date-picker:focus,
    .input-container textarea:focus {
      border: 1px solid black; }
    .input-container input[type="text"].linestyle,
    .input-container input[type="password"].linestyle,
    .input-container input[type="number"].linestyle,
    .input-container input[type="email"].linestyle,
    .input-container select.linestyle,
    .input-container .date-picker.linestyle,
    .input-container textarea.linestyle {
      border: none;
      border-bottom: 1px solid #c3c3c3;
      border-radius: 0px; }
  .input-container textarea {
    padding: 10px 5px; }
  .input-container .react-datepicker__input-container {
    width: 100%; }
  .input-container input[type="submit"],
  .input-container button {
    padding: 10px;
    font-size: 18px;
    border: none;
    color: #fff;
    background: #019541;
    border-radius: 5px;
    transition: all .2s ease-in-out;
    cursor: pointer; }
    .input-container input[type="submit"]:hover,
    .input-container button:hover {
      background: #007734; }

.panel {
  min-height: 100vh; }

.topbar {
  box-shadow: 0px 0px 20px 0px #c3c3c3;
  background: #fff;
  padding: 10px 30px 10px 230px;
  display: flex;
  justify-content: space-between;
  height: 54px;
  box-sizing: border-box;
  z-index: 99;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px; }
  .topbar .logo {
    max-width: 173px; }
  .topbar .toolbar-contant {
    display: flex;
    align-content: center; }

.panel-content {
  min-height: calc(100vh - 54px);
  display: flex;
  margin-top: 0px;
  padding-top: 54px; }
  .panel-content .sidebar {
    width: 200px;
    min-width: 200px;
    min-height: 100%;
    padding: 54px 0px 0px;
    box-sizing: border-box;
    background: white;
    margin-top: -54px;
    position: fixed;
    z-index: 99; }
    .panel-content .sidebar:after {
      content: "";
      position: absolute;
      top: 54px;
      right: 0px;
      height: calc(100% - 54px);
      width: 9px;
      box-shadow: 8px 0px 10px -3px #c3c3c3; }
    .panel-content .sidebar h2 {
      font-size: 20px;
      text-transform: uppercase;
      padding: 10px 10px;
      border-top: 1px solid #f5f5f5; }
  .panel-content .main {
    width: 100%;
    padding: 30px;
    margin-left: 200px;
    box-sizing: border-box; }

.avatar-container {
  display: flex;
  align-items: center;
  margin-right: 15px; }
  .avatar-container .avatar {
    width: 35px;
    height: 35px;
    padding: 4px 1px 0px;
    box-sizing: border-box;
    border: 1px solid #e31e24;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px; }
    .avatar-container .avatar img {
      width: 100%; }
  .avatar-container span {
    font-size: 15px;
    text-transform: uppercase;
    white-space: nowrap; }

.menu .menu-list li a {
  display: block;
  padding: 10px;
  border-top: 1px solid #f5f5f5;
  color: #333333;
  text-decoration: none;
  transition: all .2s ease-in-out;
  background: #fff; }
  .menu .menu-list li a:hover {
    background: #f5f5f5; }

.button-logout {
  background: #e31e24;
  border: none;
  color: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
  transition: all .2s ease-in-out; }
  .button-logout:hover {
    background: #b61d22; }

.box {
  box-shadow: 0px 0px 20px 0px #c3c3c3;
  background: white;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 10px 0px;
  width: 100%;
  overflow: hidden; }
  .box.size-1 {
    max-width: 600px; }
  .box.size-2 {
    max-width: 900px; }
  .box.size-100 {
    max-width: 100%; }
  .box .box-content {
    padding: 30px 20px; }
    .box .box-content .product-img img {
      width: 100%;
      margin-bottom: 10px; }
    .box .box-content img {
      min-width: 150px; }
  .box .box-title {
    background: #f5f5f5;
    padding: 15px 5px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    color: #333333; }

.list {
  list-style: none; }
  .list .title-li {
    display: flex;
    background: #f5f5f5; }
    .list .title-li span {
      display: block;
      padding: 10px; }
      .list .title-li span.flex-1 {
        flex: 1; }
      .list .title-li span.flex-2 {
        flex: 2; }
      .list .title-li span.flex-3 {
        flex: 3; }
  .list .product-item-list .product-item {
    display: flex;
    text-decoration: none;
    color: #333333;
    align-items: center; }
    .list .product-item-list .product-item .item-actions {
      display: flex;
      flex: 2; }
      .list .product-item-list .product-item .item-actions button,
      .list .product-item-list .product-item .item-actions .button {
        margin-right: 5px;
        font-size: 15px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: pre-line; }
    .list .product-item-list .product-item > * {
      flex: 1;
      padding: 10px;
      color: #333333;
      text-decoration: none; }
  .list .product-item-list.hide {
    display: none; }
  .list .small-column {
    max-width: 100px; }

.search {
  margin-bottom: 20px; }
  .search .box-content {
    padding: 5px 20px; }
  .search input {
    text-align: center; }

.notification {
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 15px 40px;
  background: #333333;
  border-radius: 5px;
  font-size: 17px;
  box-shadow: 0px 0px 20px 0px #c3c3c3;
  z-index: 999;
  transition: all .2s ease-in-out;
  transform: translateY(70px);
  color: #fff; }
  .notification.show {
    transform: translateY(0px); }
  .notification.success {
    background: #019541; }

.flex-container {
  display: flex; }
  .flex-container.flex-2 > div {
    flex: 1;
    width: 50%; }
  .flex-container.flex-3 > div {
    flex: 1;
    width: 33.333%; }
  .flex-container.flex-4 > div {
    flex: 1;
    width: 25%; }

.label {
  margin-bottom: 10px;
  display: block;
  max-width: 200px;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 5px; }
  .label h3 {
    font-size: 15px;
    margin-bottom: 5px;
    color: #e31e24; }
  .label span {
    color: #333333;
    font-size: 18px; }

.history-item {
  display: flex;
  width: 100%;
  transition: all .2s ease-in-out;
  border-bottom: 1px solid #f5f5f5; }
  .history-item span {
    padding: 15px 10px;
    flex: 1; }
  .history-item:hover {
    background: #f5f5f5; }

.history-labels {
  display: flex;
  width: 100%;
  background: #f5f5f5;
  padding: 10px 0px;
  border-bottom: 1px solid #333333; }
  .history-labels span {
    padding: 5px 10px;
    flex: 1; }

.products-list-titles {
  background: #f5f5f5; }

.user-link,
.users-list-titles,
.product-link,
.products-list-titles {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  color: #333333;
  text-decoration: none;
  transition: all .2s ease-in-out; }
  .user-link:hover,
  .users-list-titles:hover,
  .product-link:hover,
  .products-list-titles:hover {
    background: #f5f5f5; }
  .user-link.hide,
  .users-list-titles.hide,
  .product-link.hide,
  .products-list-titles.hide {
    display: none; }
  .user-link span,
  .users-list-titles span,
  .product-link span,
  .products-list-titles span {
    flex: 1;
    display: block;
    padding: 0px 10px; }
    .user-link span.size-1,
    .users-list-titles span.size-1,
    .product-link span.size-1,
    .products-list-titles span.size-1 {
      width: 45px;
      max-width: 45px; }

.client-record {
  display: flex;
  padding: 5px 0px;
  border-bottom: 1px solid #f5f5f5; }

.users-list-titles {
  background: #f5f5f5;
  padding: 15px 10px; }

.acc-item {
  display: block;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px; }
  .acc-item .acc-item-title {
    display: flex;
    padding: 5px;
    align-items: center;
    background: #ececec;
    transition: all .2s ease-in-out;
    cursor: pointer; }
    .acc-item .acc-item-title:hover {
      background: #ececec; }
    .acc-item .acc-item-title > span {
      flex: 1; }
      .acc-item .acc-item-title > span:not(.with-events) {
        pointer-events: none; }
    .acc-item .acc-item-title .button {
      width: auto;
      padding: 5px 10px;
      font-size: 15px;
      background: #e31e24; }
      .acc-item .acc-item-title .button:hover {
        background: #b61d22; }
  .acc-item .acc-item-content {
    transition: all .2s linear;
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    border: 1px solid #ececec;
    padding: 0px 10px; }
    .acc-item .acc-item-content.show {
      max-height: 500px;
      padding: 10px;
      opacity: 1; }

.acc-item-content {
  box-sizing: border-box;
  padding: 10px;
  display: flex; }
  .acc-item-content .acc-information {
    width: 100%; }
  .acc-item-content .acc-row {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .acc-item-content .acc-row .label {
      max-width: 260px;
      width: 100%;
      margin: 5px;
      min-width: 150px; }
      .acc-item-content .acc-row .label.full {
        max-width: 100%;
        box-sizing: border-box; }
    .acc-item-content .acc-row.desc {
      justify-content: flex-start; }
      .acc-item-content .acc-row.desc .label {
        max-width: 100%;
        width: auto; }
        .acc-item-content .acc-row.desc .label:first-of-type {
          margin-right: 10px; }
  .acc-item-content .acc-item-image {
    max-width: 400px;
    margin-right: 10px; }
  .acc-item-content .order-btn {
    margin-top: 20px;
    width: auto;
    float: right; }

.form-buttons-container {
  display: flex;
  justify-content: flex-end; }
  .form-buttons-container button {
    max-width: 150px; }
    .form-buttons-container button.add-prop {
      background: #333333;
      margin-right: 10px;
      max-width: 200px; }

.addField {
  border: 1px solid #ececec;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px; }
  .addField button {
    background: #333333; }

.register-product {
  display: flex; }
  .register-product > div:first-of-type:not(:last-of-type) {
    margin-right: 30px; }
  .register-product .product-prop {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    background: #f5f5f5;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px; }
    .register-product .product-prop .input-name {
      font-size: 14px;
      margin-bottom: 5px; }
    .register-product .product-prop .input-value {
      font-size: 17px; }

.product-input-prop {
  position: relative; }
  .product-input-prop .delete {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 30px;
    background: #e31e24;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 40px;
    transition: all .2s ease-in-out; }
    .product-input-prop .delete:hover {
      background: #b61d22; }

.hamburger {
  display: none; }

.users-list-titles span:nth-of-type(2),
.user-link span:nth-of-type(2) {
  width: 150px;
  max-width: 180px;
  min-width: 130px; }

.remove-client-btn {
  max-width: 70px;
  transition: all .2s ease-in-out;
  background: #e31e24; }
  .remove-client-btn:hover {
    background: #b61d22; }

.back-arrow {
  width: auto;
  color: #333333;
  width: auto;
  color: #fff;
  padding: 5px 10px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .back-arrow .arrow {
    margin: 9px 15px; }
    .back-arrow .arrow::after,
    .back-arrow .arrow .arrow::after {
      border-color: #fff; }
    .back-arrow .arrow.is-triangle::after,
    .back-arrow .arrow .arrow.is-triangle::after {
      border-color: transparent;
      border-bottom-color: #fff; }
    .back-arrow .arrow::before,
    .back-arrow .arrow .arrow::before {
      background-color: #fff; }

.copy {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 600px;
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box; }
  .copy img {
    width: 100%; }

.login-container {
  max-width: 400px;
  margin: 0px auto;
  padding-top: 24vh; }
  .login-container > .toggleBtn {
    text-align: center;
    padding: 20px 0px; }
    .login-container > .toggleBtn button {
      border: none;
      background: none;
      font-size: 16px;
      color: #333333; }
  .login-container h2 {
    text-align: center;
    font-size: 20px;
    padding: 15px 0px;
    color: #019541; }
  .login-container .page-logo {
    text-align: center;
    margin-bottom: 30px; }
    .login-container .page-logo img {
      max-width: 100%; }
  .login-container .login-box {
    background: white;
    box-sizing: border-box;
    padding: 15px 20px;
    box-shadow: 0px 0px 20px 0px #c3c3c3;
    border-radius: 5px;
    max-width: 95%;
    margin: 0 auto; }
    .login-container .login-box button {
      width: 100%;
      padding: 10px;
      font-size: 18px;
      border: none;
      color: #fff;
      background: #019541;
      border-radius: 5px;
      transition: all .2s ease-in-out;
      cursor: pointer; }
      .login-container .login-box button:hover {
        background: #007734; }
  .login-container .error {
    text-align: center;
    font-size: 15px;
    display: block;
    color: #e31e24; }

.re-captcha {
  margin: 10px 0px 15px; }

@media screen and (max-width: 1220px) {
  .label {
    margin: 0px 5px 10px 5px; }
  .users-list-titles span:nth-of-type(5),
  .user-link span:nth-of-type(5),
  .users-list-titles span:nth-of-type(6),
  .user-link span:nth-of-type(6) {
    display: none; }
  .list .title-li span:nth-of-type(3),
  .list .title-li span:nth-of-type(5) {
    display: none; }
  .list .product-item-list .product-item {
    flex-wrap: wrap; }
    .list .product-item-list .product-item > span:nth-of-type(2) {
      display: none; }
    .list .product-item-list .product-item .item-actions {
      width: 100%;
      min-width: 100%; } }

@media screen and (max-width: 980px) {
  .hamburger {
    font-size: 29px;
    display: flex;
    align-items: center;
    margin-right: 50px; }
  .button-logout {
    padding: 5px 10px; }
  .panel-content .sidebar {
    transform: translateX(-100%);
    transition: all .2s ease-in-out; }
    .panel-content .sidebar.show {
      transform: translateX(0%); }
  .topbar {
    padding: 10px 10px; }
  .panel-content .main {
    margin-left: 0px;
    padding: 30px 10px; }
  .register-product {
    display: flex;
    flex-direction: column; }
    .register-product > div:first-of-type:not(:last-of-type) {
      margin-right: 0px;
      margin-bottom: 20px; }
  .acc-item-content .acc-row {
    flex-wrap: wrap; } }

@media screen and (max-width: 768px) {
  .acc-postcode {
    display: none; }
  .acc-item .acc-item-content.show {
    max-height: 1900px; }
  .users-list-titles span:nth-of-type(3),
  .user-link span:nth-of-type(3) {
    width: 200px;
    max-width: 200px;
    min-width: 150px; }
  .flex-container {
    flex-wrap: wrap; }
    .flex-container.flex-4 > div {
      flex: 1 1;
      width: 100%;
      min-width: 100%; }
  .history-item {
    flex-direction: column;
    border-bottom: 1px solid #555a02; }
  .avatar-container {
    display: none; }
  .copy {
    padding: 5px; } }

@media screen and (max-width: 540px) {
  .notification {
    padding: 15px 10px;
    width: calc(100% - 40px);
    transform: translateY(100px); }
  .acc-item-content {
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .acc-item-content .acc-row {
      flex-wrap: nowrap;
      flex-direction: column; }
  .acc-item .acc-item-title > span:nth-of-type(3) {
    display: none; }
  .form-buttons-container {
    align-items: center;
    flex-direction: column; }
  .form-buttons-container button.add-prop {
    margin: 0px 0px 10px; }
  .users-list-titles span:nth-of-type(1),
  .user-link span:nth-of-type(1),
  .users-list-titles span:nth-of-type(3),
  .user-link span:nth-of-type(3),
  .users-list-titles span:nth-of-type(5),
  .user-link span:nth-of-type(5) {
    display: none; }
  .users-list-titles span:nth-of-type(7),
  .user-link button {
    display: none; }
  .acc-product-name {
    display: none; } }
